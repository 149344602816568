<template>
  <!--搜索-->
  <div class="search">
    <music-loading v-model="isShowLoad" />
    <div class="search-head">
      <input v-model.trim="searchKeyword" class="search-input" type="text" placeholder="搜索：音乐、歌手" @keyup.enter="onSearch" />
      <div class="search-hot">
        <span class="tip">大家都在搜：</span>
        <span class="word" v-for="(item, index) in searchHotKeyword" :key="index" @click="onSearchHot(item.first)">
          {{ item.first }}
        </span>
      </div>
    </div>
    <music-list ref="musicList" :list="searchList" list-type="pullup" @select="selectItem" @pullUp="pullUpLoad" />
  </div>
</template>

<script>
  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex'
  import {
    search,
    searchHot,
    getMusicDetail
  } from 'api'
  import formatSongs from '@/utils/song'
  import { loadMixin } from '@/utils/mixin'
  import { toHttps } from '@/utils/util'
  import MusicLoading from 'components/music-common/loading/music-loading'
  import MusicList from 'components/music-list/music-list'

  export default {
    name: 'Search',
    components: {
      MusicLoading,
      MusicList
    },
    mixins: [loadMixin],
    data() {
      return {
        searchKeyword: '', // 搜索关键词
        searchHotKeyword: [], // 热搜关键词
        searchList: [], // 搜索数组
        page: 0, // 分页
        lockUp: true // 加载事件
      }
    },
    computed: {
      ...mapGetters(['playing', 'currentMusic'])
    },
    watch: {
      list(newList, oldList) {
        if (newList.length !== oldList.length) {
          this.lockUp = false
        } else if (
          newList[newList.length - 1].id !== oldList[oldList.length - 1].id
        ) {
          this.lockUp = false
        }
      }
    },
    created() {
      // 获取热搜
      searchHot().then(({
        result
      }) => {
        // this.searchHotKeyword = result.hots.slice(0, 5)
        this.searchHotKeyword = result.hots
        this.isShowLoad = false
      })
    },
    methods: {
      // 搜索事件
      onSearch() {
        if (this.searchKeyword.replace(/(^\s+)|(\s+$)/g, '') === '') {
          this.$Toast('请输入搜索内容')
          return
        }

        this.isShowLoad = true
        this.page = 0
        if (this.searchList.length > 0) {
          this.$refs.musicList.scrollTo()
        }
        search(this.searchKeyword).then(({
          result
        }) => {
          this.searchList = formatSongs(result.songs)
          this._hideLoad()
        })
      },
      // 点击热搜
      onSearchHot(name) {
        this.searchKeyword = name
        this.onSearch()
      },
      // 滚动加载事件
      pullUpLoad() {
        this.page += 1
        search(this.searchKeyword, this.page).then(({
          result
        }) => {
          if (!result.songs) {
            this.$Toast('已经到底了')
            return
          }
          this.searchList = [...this.searchList, ...formatSongs(result.songs)]
        })
      },
      // 播放歌曲
      async selectItem(music) {
        try {
          const image = await this._getMusicDetail(music.id)
          music.image = toHttps(image)
          this.selectAddPlay(music)
        } catch (error) {
          this.$Toast('歌曲播放失败')
        }
      },
      // 获取歌曲详情
      _getMusicDetail(id) {
        return getMusicDetail(id).then((res) => res.songs[0].al.picUrl)
      },
      ...mapMutations({
        setPlaying: 'SET_PLAYING'
      }),
      ...mapActions(['selectAddPlay'])
    }
  }
</script>

<style lang="less" scoped>
  .search {
    position: relative;
    width: 100%;
    height: 100%;

    .search-head {
      height: 70px;
      padding: 10px 15px;
      overflow: hidden;
      background: rgba(0, 0, 0, .1);
      border-radius: 20px;
      text-align: center;
      .search-hot{
        width: 80%;
        margin: 0 auto;
        text-align: left;
        .tip{
          font-size: @font_size_medium;
          font-weight: bolder;
          color: rgba(215,225,125,.6);
        }
        .word {
          margin-right: 10px;
          line-height: 40px;
          cursor: url('https://qiniu-music.prochick.top/cursor/S2.cur'), auto !important;

          &:hover {
            color: @text_color_active;
          }
        }
      }


      .search-input {
        width: 80%;
        height: 40px;
        box-sizing: border-box;
        padding: 0 15px;
        border: 1px solid @btn_color;
        border-radius: 20px;
        outline: 0;
        background: transparent;
        color: @text_color_active;
        font-size: @font_size_medium;
        box-shadow: 0 0 1px 0 #fff inset;

        &::placeholder {
          color: @text_color;
        }
      }
    }

    .musicList {
      width: 100%;
      height: calc(~'100% - 90px');
    }
  }

  ::-webkit-scrollbar-thumb {
    visibility: hidden;
    background: #757F9A;
    background: -webkit-linear-gradient(to right, #D7DDE8, #757F9A);
    background: linear-gradient(to right, #D7DDE8, #757F9A);
    border-radius: 10px;
    transition: all 0.8s;
  }

  .musicList:hover ::-webkit-scrollbar-thumb {
    visibility: visible;
  }
</style>
